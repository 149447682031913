<template>
  <div>
    <div class="user-title">
      <h2>质检报告</h2>
    </div>
    <div id="user_order_list">
    </div>
  </div>
</template>

<script>
import {getQCReportPage} from "@/api/user";
import settings from "@/utils/settings";

/**
 * 质检报告
 */
export default {
  name: "QCReport",
  created() {
    this.getQCReportPage()
  },
  methods:{
    getQCReportPage(){
      getQCReportPage({
        limit: 10,
        page: 1,
        goodsNo: "aaabbbccc"
      }).then(res => {
        this.orders = res.data.list
        this.orders.forEach((o)=>{
          o.items.forEach((i)=>{
            if(i.imageUrl.indexOf("image.yabyy.com") == -1){
              i.imageUrl = settings.AdminUrl + i.imageUrl
            }
          })
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
